import React from 'react';
import PropTypes from "prop-types"
import {Button, Checkbox, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Row, Modal} from "react-bootstrap";
import {merge, updateExternalLinks} from "../../common/commonHandlers";
import {bindActionCreators} from 'redux'
import createApiService from "../../common/clientActionsBuilder";
import {connect} from "react-redux";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {ContentState, EditorState} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import {getClient, getRecipients, getTemplate, renderEmail, saveEditorSettings} from './EmailEditorApi'
import Select from "react-select";
import Dropzone from "react-dropzone";
import './dropzone.css';
import ResourceComponent from "../ResourceComponent";
import Datetime from "react-datetime";
import moment from "moment"
import TextareaAutosize from "react-textarea-autosize";
import JoditEditor from "jodit-react";
import EmailEditorPreview from "../../containers/ClientsPage/tabs/EmailEditorPreview";
import {MaterialSwitch} from "../MaterialSwitch/MaterialSwitch";
import {JODIT_BUTTONS} from "../../constants";
import {Jodit} from "jodit";
import {Editor} from "@tinymce/tinymce-react";
import config from "../../config/environment";
import _ from "lodash";

const api = createApiService('email_templates', 'email_template', 'Email template');


class EmailEditor extends ResourceComponent {

    constructor(props) {
        super(props);
        this.editorRef = React.createRef()
        this.defaultValues = {
            subject: '',
            from: '',
            reply_to: '',
            recipient: '',
            message: '',
            follow_up_date: moment().add(3, 'days').hour(6).minute(0),
            send_blind_copy_to_me: true,
            no_follow_up: props.withFollowUpDate === false,
            attach_pdf: false,
            confirmMessage: false,
        };
        if (props.defaultEmail) {
            this.defaultValues.from = props.defaultEmail;
            this.defaultValues.reply_to = props.defaultEmail;
        }
        if (props.recipient) {
            this.defaultValues.recipient = this.uniqEmailRecipients(props.recipient);
        }
        let cc_recipients = [];
        if (props.cc_recipients) {
            props.cc_recipients.forEach(cc =>
                cc_recipients.push({email: cc.email_address})
            )
        }
        this.defaultValues.cc_recipients = cc_recipients;
        let bcc_recipients = [];
        bcc_recipients.push({email: props.defaultEmail})
        this.defaultValues.bcc_recipients = bcc_recipients;
        this.state = {
            resource: this.defaultValues,
            emailTemplates: [],
            isPreviewModalOpened: false,
            showEmailsModal: false,
            emailRecipients: [],
            emailModalRecipients: [],
            advancedView: false,
            config: this.basicConfig,
            showHtmlModal: false,
            isJodit: false,
            tinyInitialValue: ""
        };
        if (this.state.resource && this.state.emailRecipients > 0) {
            this.handleRecipients()
        }
    }

    tinyMceRef = React.createRef();

    basicConfig = {
        readonly: false,
        toolbarInline: true,
        popup: {
            cells: [...JODIT_BUTTONS.basic.popupButtons],
        },
        height: 500,
        enter: 'div',
        extraButtons: [{
            name: 'preview',
            exec: () => this.setState({isPreviewModalOpened: true})
        }],
        colorPickerDefaultTab: "color",
        extraPlugins: ['tooltip'],
        showTooltipDelay: 0,
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        defaultActionOnPaste: "insert_only_text",
        ...JODIT_BUTTONS.basic
    }

    updateJoditConfig = () => {
        const {advancedView} = this.state;
        const advancedConfig = {
            readonly: false,
            enter: 'div',
            buttons: [
                ...Jodit.defaultOptions.buttons.filter(b => b.group !== "source"),
                {
                    name: 'source',
                    exec: (editor) => {
                        editor.selection.clear();
                        editor.selection.remove();
                        setTimeout(() => {
                            editor.setMode(editor.mode === 1 ? 2 : 1)
                        }, 100)
                    }
                }
            ],
            extraButtons: [{
                name: 'preview',
                exec: () => this.setState({isPreviewModalOpened: true})
            }],
            popup: {
                cells: [
                    ...JODIT_BUTTONS.advanced.popupButtons,
                    Jodit.defaultOptions.popup.cells[6],
                    Jodit.defaultOptions.popup.cells[7],
                    Jodit.defaultOptions.popup.cells[8]
                ]
            },
            height: 500,
            colorPickerDefaultTab: "color",
            extraPlugins: ['tooltip'],
            showTooltipDelay: 0,
            useNativeTooltip: true,
            askBeforePasteHTML: this.state.showHtmlModal,
            askBeforePasteFromWord: false,
            defaultActionOnPaste: "insert_only_text"
        };

        this.setState({
            config: advancedView ? advancedConfig : this.basicConfig,
        });
    };

    setSpellCheck = () => this.editorRef?.current && this.editorRef.current.setAttribute('spellCheck', true)
    componentDidMount = () => {
        const {setCurrentEmail, client, clientId} = this.props

        this.setSpellCheck()
        if (client) {
            this.props.actions.getClient(client.id, (res) => {
                res.attach_pdf_to_email && this.handleAttachPdf({
                    target: {
                        type: 'checkbox',
                        name: 'attach_pdf',
                        checked: true
                    }
                })
                const parsed_editor_settings = res.editor_settings
                this.setState({isJodit: parsed_editor_settings?.email_editor === 'jodit'})
            })
        }
        if (!client && clientId) {
            this.props.actions.getClient(clientId, (res) => {
                const parsed_editor_settings = res.editor_settings
                this.setState({isJodit: parsed_editor_settings?.email_editor === 'jodit'})
            })
        }
        setCurrentEmail && setCurrentEmail(this.handleDuplicateEmail)
        this.load()
    };

    load = () => {
        const {roles, emailType, technicianOnly, bulkEmailType, templateId} = this.props
        const isTechnician = roles?.length === 1 && roles[0] === 'technician'
        this.props.actions.load({technicianOnly: !!technicianOnly}, e => {
            const filtered = e.filter(e => {
                let correct = e.validate_shortcodes_for?.replaceAll(" ", "_") === emailType?.replaceAll(" ", "_") || !e.validate_shortcodes_for
                if (emailType === "bulk") {
                    correct = correct || bulkEmailType?.replaceAll(" ", "_") === e.validate_shortcodes_for?.replaceAll(" ", "_")

                    if(bulkEmailType === 'paid'){
                        correct = correct || e.validate_shortcodes_for?.replaceAll(" ", "_") === "invoice_paid"
                    }

                }
                if (emailType?.replaceAll(" ", "_") === "bulk_invoiced") {
                    correct = correct || e.validate_shortcodes_for === "invoice"
                }
                return correct
            })

            this.setState({emailTemplates: filtered}, () => {
                let defaultTemplate;
                if (isTechnician) {
                    defaultTemplate = filtered[0]
                } else {
                    if (emailType === 'bulk_invoiced') {
                        defaultTemplate = filtered.find(x => x.default_for === 'invoice');
                    } else {
                        defaultTemplate = filtered.find(x => x.default_for === emailType);
                    }
                }
                if (templateId) {
                    defaultTemplate = e.find(x => x.id === templateId);
                }
                defaultTemplate && !this.state.emailTemplate && this.handleEmailTemplateSelect({
                    value: defaultTemplate.id,
                    label: defaultTemplate.name
                });
                const elements = document.querySelectorAll('.in.modal')
                elements && elements[elements.length - 1].removeAttribute('tabindex');
            })
        })
        if ((emailType === 'bulk_invoiced' || emailType === 'invoice' || emailType === 'invoice paid') && this.props.referenceId) {
            this.props.actions.getRecipients(Array.isArray(this.props.referenceId) ? this.props.referenceId : [this.props.referenceId],
                emailRecipients => {
                    if (emailRecipients) {
                        this.setState({
                            emailRecipients: emailRecipients.data,
                            emailModalRecipients: emailRecipients.data
                        }, () => {
                            this.handleRecipients();
                            this.handleAttachPdf({
                                target: {
                                    type: 'checkbox',
                                    name: 'attach_pdf',
                                    checked: true
                                }
                            })
                        })
                    }
                })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.draftSaved !== prevProps.draftSaved && this.props.draftSaved === true) {
            this.load()
            this.props.changeDraftSavedStatus()
        }
        if (prevState.advancedView !== this.state.advancedView || prevState.showHtmlModal !== this.state.showHtmlModal) {
            this.updateJoditConfig();
        }
        if (prevState.isJodit !== this.state.isJodit) {
            this.setState({tinyInitialValue: this.state.resource.message || ""})
        }
        this.props.handleChange(prevState);
    }

    onEditorStateChange = (editorState) => {
        const {resource} = this.state;
        resource.message = editorState;
        this.setState(resource);
    };

    handleAttachPdf = (e) => {
        const {dataPdf} = this.props
        if (dataPdf) {
            this.updateResourceAttr(e)
            if (this.state.resource.attach_pdf && this.state?.emailRecipients?.length > 0) {
                this.props.attachBackgroundPdf(
                    dataPdf,
                    this.state.emailRecipients[0]?.background_pdf_token
                );
            } else {
                const attachToken = this.props.attachments.find(a => a.filename === `${dataPdf.client_name}-Invoice #${dataPdf.number}.pdf`)?.token
                if (attachToken) {
                    this.props.removeAttachment(attachToken)
                }
            }
        }
    }

    uniqEmailRecipients = (recipients) => {
        if (typeof recipients === 'string') {
            const emailArray = recipients.split(',').map(email => email.trim());

            const uniqueEmailArray = [];
            emailArray.forEach(email => {
                if (email && email.length > 0 && !uniqueEmailArray.includes(email)) {
                    uniqueEmailArray.push(email);
                }
            });

            return uniqueEmailArray.join(', ')
        } else {
            return recipients
        }
    }


    handleEmailTemplateSelect = e => {
        if (e) {

            const templateCallback = x => {
                const emailTemplate = this.state.emailTemplates.find(t => t.id === e.value)
                let email = merge(e.value, this.defaultValues);
                email = merge(x, email);
                if (emailTemplate.from) {
                    email.from = emailTemplate.from
                }
                if (emailTemplate.reply_to) {
                    email.reply_to = emailTemplate.reply_to
                }
                if (emailTemplate.bcc) {
                    email.bcc_recipients = [{email: emailTemplate.bcc}]
                }

                this.setState({resource: email, emailTemplate: e}, () => {
                    this.props.setEmailTemplate(emailTemplate)
                    this.handleRecipients()
                });
                this.updateEditorState(email.message);
            };
            if (this.props.templateMode) {
                this.props.actions.getTemplate(e.value, templateCallback);
            } else {
                this.props.actions.renderEmail(e.value, this.props.emailType, this.props.referenceId, templateCallback);
            }

        } else {
            this.setState({resource: this.defaultValues, emailTemplate: null}, () => this.props.setEmailTemplate(null));
            this.updateEditorState('');
        }
    };

    handleDuplicateEmail = e => {
        const templateCallback = x => {
            let email = merge(e, this.defaultValues);
            email = merge(x, email);
            this.setState({resource: email, emailTemplate: {label: email.name, value: email.id}}, () => {
                this.props.setEmailTemplate(e)
                this.handleRecipients()
            });
            this.updateEditorState(email.message);
        };

        if (this.props.templateMode) {
            this.props.actions.getTemplate(e.id, templateCallback);
        } else {
            this.props.actions.renderEmail(e.id, this.props.emailType, this.props.referenceId, templateCallback);
        }

    }

    updateEditorState(message) {
        const contentBlock = htmlToDraft(message);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        this.setState({editorState});
    }

    setInnerHTML = (text) => {
        return <div dangerouslySetInnerHTML={{__html: text}}/>
    }

    handleRecipients = () => {
        const {resource, emailRecipients, emailModalRecipients} = this.state
        const {emailType} = this.props
        if ((emailType === 'invoice' || emailType === 'invoice paid') && emailRecipients && emailRecipients.length > 0) {
            let recipient = ''
            const cc_recipients = []
            emailRecipients[0] && emailRecipients[0].emails && emailRecipients[0].emails.length > 0 && emailRecipients[0].emails.map(e => {
                if (e.show) {
                    if (recipient.length === 0) {
                        recipient = e.email_address
                    } else {
                        if (e.email_address.includes(",")) {
                            e.email_address.split(",").map(e => {
                                cc_recipients.push({email: e.trim()})
                            })
                        } else {
                            cc_recipients.push({email: e.email_address})
                        }
                    }
                }
            })
            resource.recipient = recipient
            resource.cc_recipients = _.uniqBy(cc_recipients, "email");
            this.setState({resource: resource})
        } else if (emailType === 'bulk_invoiced' && emailModalRecipients.length > 0) {
            this.setState({emailRecipients: emailModalRecipients})
        }
    }

    ai_request = (request, respondWith) => {
        const openAiOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${config.tinyMceKey}`
            },
            body: JSON.stringify({
                model: 'gpt-4o',
                temperature: 0.7,
                max_tokens: 800,
                messages: [{ role: 'user', content: request.prompt }],
            })
        };
        respondWith.string((signal) => window.fetch('https://api.openai.com/v1/chat/completions', { signal, ...openAiOptions })
            .then(async (response) => {
                if (response) {
                    const data = await response.json();
                    if (data.error) {
                        throw new Error(`${data.error.type}: ${data.error.message}`);
                    } else if (response.ok) {
                        return data?.choices[0]?.message?.content?.trim();
                    }
                } else {
                    throw new Error('Failed to communicate with the ChatGPT API');
                }
            })
        );
    };

    render() {
        const {
            resource,
            emailTemplate,
            emailTemplates,
            isPreviewModalOpened,
            showEmailsModal,
            emailRecipients,
            emailModalRecipients,
            advancedView,
            showHtmlModal,
            isJodit
        } = this.state;
        const {
            uploadAttachment,
            removeAttachment,
            attachments,
            message,
            client,
            confirmMessage,
            emailType,
            selectedRows
        } = this.props;
        return resource ?
            <form>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <ControlLabel>
                                Email template
                            </ControlLabel>
                            <Select className="Select" classNamePrefix="select"
                                    name="emailTemplate"
                                    value={emailTemplate}
                                    options={emailTemplates.map(x => ({value: x.id, label: x.name}))}
                                    onChange={this.handleEmailTemplateSelect}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <ControlLabel>
                                Do not create follow up
                            </ControlLabel>
                            <Checkbox
                                name="no_follow_up"
                                onChange={this.updateResourceAttr}
                                checked={resource.no_follow_up}
                            />
                        </FormGroup>
                    </Col>
                    {!resource.no_follow_up &&
                        <Col md={4}>
                            <FormGroup>
                                <ControlLabel>
                                    Follow up date
                                </ControlLabel>
                                <Datetime
                                    value={resource.follow_up_date}
                                    onChange={this.dateResourceAttr("follow_up_date")}/>
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>
                                    Follow up message
                                </ControlLabel>
                                <TextareaAutosize
                                    style={{width: '100%'}}
                                    name="body"
                                    value={resource.body}
                                    onChange={(e) => {
                                        resource.body = e.target.value;
                                        this.setState({resource})
                                    }}
                                    minRows={3}
                                    maxRows={10}
                                    placeholder=""
                                />
                            </FormGroup>
                        </Col>
                    }
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <ControlLabel>
                                From
                            </ControlLabel>
                            <FormControl
                                name="from"
                                onChange={this.updateResourceAttr}
                                value={resource.from}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <ControlLabel>
                                Reply to
                            </ControlLabel>
                            <FormControl
                                name="reply_to"
                                onChange={this.updateResourceAttr}
                                value={resource.reply_to}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup className='vertical-align'>
                            <ControlLabel>
                                Send me a blind copy
                            </ControlLabel>
                            <Checkbox
                                name="send_blind_copy_to_me"
                                onChange={this.updateResourceAttr}
                                checked={resource.send_blind_copy_to_me}
                                className='marginLeft10'
                            />
                        </FormGroup>
                        {resource.send_blind_copy_to_me &&
                            <div>
                                <ControlLabel>
                                    BCC:
                                </ControlLabel>
                                <FormGroup className='vertical-align full-width'>
                                    <TextareaAutosize
                                        wrap="soft"
                                        className='full-width'
                                        name='bcc_recipients'
                                        onChange={(e) => {
                                            let {resource} = this.state;
                                            let bcc_recipients = e.target.value.split(', ');
                                            let mapped_bcc = [];
                                            bcc_recipients.forEach(bcc => {
                                                mapped_bcc.push({email: bcc})
                                            })
                                            resource.bcc_recipients = mapped_bcc;
                                            this.setState(resource)
                                        }
                                        }
                                        value={resource.bcc_recipients.map(bcc => bcc.email).join(', ')}
                                    />
                                </FormGroup>
                            </div>
                        }
                    </Col>
                </Row>
                <Row>
                    {!this.props.templateMode && <Col md={6}>
                        <FormGroup>
                            <ControlLabel>
                                Recipient
                            </ControlLabel>
                            <FormControl
                                componentClass="textarea"
                                name="recipient"
                                onChange={this.updateResourceAttr}
                                value={resource.recipient}
                            />
                        </FormGroup>
                        {((resource.cc_recipients && resource.cc_recipients.length > 0) || client?.arborist_as_technician_cc) &&
                            <div>
                                <ControlLabel>
                                    CC:
                                </ControlLabel>
                                <FormGroup
                                    className={`vertical-align full-width ${(emailType === 'invoice' || emailType === 'invoice paid') ? "bottom5" : ""}`}>
                                    <TextareaAutosize
                                        wrap="soft"
                                        className='full-width'
                                        name='cc_recipients'
                                        onChange={(e) => {
                                            let {resource} = this.state;
                                            let cc_recipients = e.target.value.split(', ');
                                            let mapped_cc = [];
                                            cc_recipients.forEach(cc => {
                                                mapped_cc.push({email: cc})
                                            })
                                            resource.cc_recipients = mapped_cc;
                                            this.setState(resource)
                                        }
                                        }
                                        value={resource.cc_recipients.map(cc => cc.email).join(', ')}
                                    />
                                </FormGroup>
                            </div>}
                        {(emailType === 'invoice' || emailType === 'invoice paid') &&
                            <Button bsSize='xsmall' bsStyle="success" className="bottom10"
                                    onClick={() => this.setState({
                                        showEmailsModal: true,
                                        emailModalRecipients: JSON.parse(JSON.stringify(emailRecipients))
                                    })}>
                                Manage Recipients
                            </Button>
                        }
                    </Col>}
                    {uploadAttachment && <Col md={6}>
                        <FormGroup>
                            <ControlLabel>
                                Attachments
                            </ControlLabel>
                            <Dropzone onDrop={uploadAttachment}
                                      className="dropzone pointer">
                                <p>Drop a file here or click to select a file to upload.</p>
                            </Dropzone>
                            {attachments && attachments.map(i => {
                                return (
                                    <div key={i.token}>
                                        {i.filename}&nbsp;
                                        <a className="pointer"
                                           title="remove attachment"
                                           onClick={() => {
                                               if (i.token === this.props.attachments.find(a => a.filename === `${this.props.dataPdf?.client_name}-Invoice #${this.props.dataPdf?.number}.pdf`)?.token) {
                                                   this.setState({resource: {...resource, attach_pdf: false}})
                                                   removeAttachment(i.token)
                                               } else {
                                                   removeAttachment(i.token)
                                               }
                                           }}>
                        <span className="text-danger">
                        <Glyphicon glyph="remove-sign"/>
                        </span>
                                        </a>
                                    </div>
                                )
                            })}
                        </FormGroup>
                        {this.props.dataPdf && <FormGroup className='vertical-align'>
                            <ControlLabel>
                                Attach Invoice PDF
                            </ControlLabel>
                            <Checkbox
                                name="attach_pdf"
                                onChange={(e) => this.handleAttachPdf(e)}
                                checked={resource.attach_pdf}
                                className='marginLeft10'
                            />
                        </FormGroup>}
                    </Col>}
                </Row>
                {message && <Row>
                    <Col md={12}>
                        <h3>{message}</h3>
                    </Col>
                </Row>}
                {confirmMessage && <Row>
                    <Col md={12}>
                        {emailType === 'bulk_invoiced' ? <h3>You are about to send to {" "}
                            <u className="pointer" onClick={() => {
                                this.setState({
                                    showEmailsModal: true,
                                    emailModalRecipients: JSON.parse(JSON.stringify(emailRecipients))
                                })
                            }}>
                                {emailRecipients && emailRecipients.length} customers
                            </u>
                            . Are you sure you mean to do this?</h3> : <h3>You are about to send{" "}
                            <u className="pointer" onClick={() => this.setState({showEmailsModal: true})}>
                                {selectedRows && selectedRows.length} emails
                            </u>
                            . Are you sure you mean to do this?</h3>}
                    </Col>
                </Row>}
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <ControlLabel>
                                Subject
                            </ControlLabel>
                            <FormControl
                                name="subject"
                                onChange={this.updateResourceAttr}
                                value={resource.subject}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <EmailEditorPreview
                        title={`Preview mode`}
                        show={isPreviewModalOpened}
                        onHide={() => this.setState({isPreviewModalOpened: !isPreviewModalOpened})}
                        closeButton={() => this.setState({isPreviewModalOpened: !isPreviewModalOpened})}
                    >
                        <p className="line_height15">{this.setInnerHTML(resource.message)}</p>
                    </EmailEditorPreview>
                    <Col md={12} className="jodit-container-col">
                        <FormGroup>
                            <div className="space-between">
                                <ControlLabel>
                                    Message
                                </ControlLabel>
                                <div className="d-flex flex-gap-10">
                                    <MaterialSwitch
                                        value={isJodit}
                                        onChange={() => {
                                            this.setState({isJodit: !isJodit})

                                            this.props.actions.saveEditorSettings(
                                                JSON.stringify({
                                                    place: "email_editor",
                                                    value: !isJodit ? "jodit" : "tinyMce"
                                                }),
                                                this.props.clientId
                                            )
                                        }}
                                        label={"TinyMCE"}
                                        label2={"Jodit"}
                                    />
                                    {isJodit && <>
                                        {advancedView && <MaterialSwitch
                                            style={{marginRight: 20}}
                                            value={showHtmlModal}
                                            onChange={() => this.setState({showHtmlModal: !showHtmlModal})}
                                            label={"Show HTML Modal"}
                                        />}
                                        <MaterialSwitch
                                            value={advancedView}
                                            onChange={() => this.setState({advancedView: !advancedView})}
                                            label={"Advanced view"}
                                        />
                                    </>}
                                </div>
                            </div>
                            {isJodit ? <JoditEditor
                                editorRef={this.setRef}
                                value={updateExternalLinks(null, this.state.resource.message)}
                                config={this.state.config}
                                onChange={(newValue) => this.onEditorStateChange(newValue)}
                                onBlur={(newValue) => this.onEditorStateChange(newValue)}
                            /> : <>
                                <Editor
                                    apiKey={config.tinyMceKey}
                                    onInit={(evt, editor) => this.tinyMceRef.current = editor}
                                    initialValue={this.state.tinyInitialValue}
                                    value={updateExternalLinks(null, resource.message)}
                                    onEditorChange={(newValue) => this.onEditorStateChange(newValue)}
                                    init={{
                                        convert_urls: false,
                                        height: 500,
                                        menubar: true,
                                        plugins: [
                                            'ai', 'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount',
                                            'tinymcespellchecker'
                                        ],
                                        toolbar: 'undo redo | aidialog aishortcuts | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | spellchecker | help | shortcodes',
                                        spellchecker_language: 'en_US',
                                        toolbar_mode: 'wrap',
                                        ai_request: this.ai_request,
                                        mobile: {
                                            toolbar_mode: 'wrap'
                                        }
                                    }}
                                />
                            </>}
                        </FormGroup>
                    </Col>
                </Row>
                {showEmailsModal && <Modal
                    bsSize="large"
                    show={showEmailsModal}
                    onHide={() => this.setState({showEmailsModal: false, emailModalRecipients: emailRecipients})}
                    className={"heightAuto maxHeight90 modalLeadsFilterBody"}
                >
                    <Modal.Header closeButton={() => this.setState({
                        showEmailsModal: false,
                        emailModalRecipients: emailRecipients
                    })}>
                        <Modal.Title className="text-center">
                            Recipients
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="max-height-300">
                        {emailType !== 'bulk_invoiced' && selectedRows && selectedRows.length > 0 ?
                            <table>
                                <tbody>
                                {selectedRows.map((e, i) => {
                                    const emailExists = e.email && e.email.length > 0
                                    return (<tr>
                                        <td className="text-right width50 padding-right-8"><h5
                                            className={emailExists ? "" : "text-danger"}>{e.name}</h5></td>
                                        <td className="text-left width50 padding-left-8">
                                            {emailExists ?
                                                <h5>{e.email}</h5> :
                                                <span className="vcenter">
                                                    <h5 className="text-danger">This user does not have an email address.</h5>
                                                    <Glyphicon className="ml-6" glyph="exclamation-sign"/>
                                                    <Button
                                                        bsSize="xsmall"
                                                        bsStyle="warning"
                                                        className="ml-6"
                                                        onClick={() => this.props.removeRecipientFromSelectedRows(i)}
                                                    >
                                                        Remove
                                                    </Button>
                                                </span>}
                                        </td>
                                    </tr>)
                                })}
                                </tbody>
                            </table>
                            :
                            emailModalRecipients && emailModalRecipients.length > 0 && emailModalRecipients.map((e, index) => {
                                return (
                                    <Row>
                                        <Col xs={12} sm={5}>
                                            <p>{e.customer}{' '}
                                                ({e.proposal_ids.map((i, index) => {
                                                    return <span>#<a href={`/proposals/${i}/invoices`}
                                                                     target='_blank'>{e.invoice_numbers[index]}{' '}</a></span>
                                                })})</p>
                                        </Col>
                                        <Col xs={12} sm={7}>
                                            {e.emails.map((e, i) => {
                                                return (
                                                    <Row>
                                                        <Col xs={6} className='no-right-padding break-word-issue'>{e.show ?
                                                            <p className='no-margin'>{e.email_address}</p> :
                                                            <s>{e.email_address}</s>}</Col>
                                                        <Col xs={2} className="no-padding text-right">
                                                            <Glyphicon
                                                                glyph='ok-sign'
                                                                className="pointer"
                                                                style={{color: e.show ? 'green' : 'gray'}}
                                                                onClick={() => {
                                                                    emailModalRecipients[index].emails[i].show = true
                                                                    this.setState({emailModalRecipients: emailModalRecipients})
                                                                }}
                                                            />
                                                            <Glyphicon
                                                                glyph='remove-sign'
                                                                className="pointer"
                                                                style={{color: e.show ? 'gray' : 'red'}}
                                                                onClick={() => {
                                                                    emailModalRecipients[index].emails[i].show = false
                                                                    this.setState({emailModalRecipients: emailModalRecipients})
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col xs={4} className='no-right-padding'>{e.email_type}</Col>
                                                    </Row>
                                                )
                                            })}
                                        </Col>
                                        {emailModalRecipients.length - 1 !== index && <Col xs={12}>
                                            <hr/>
                                        </Col>}
                                    </Row>
                                )
                            })
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Row>
                            <Col className="text-left" xs={6}>
                                {(emailType !== 'bulk_invoiced' && selectedRows && selectedRows.length > 0) && <Button
                                    bsStyle="warning"
                                    bsSize="small"
                                    disabled={!(selectedRows && selectedRows.some(r => r.email?.length === 0 || !r.email))}
                                    onClick={() => this.props.removeAllRecipientsWithoutEmail()}
                                >
                                    Remove recipients without email
                                </Button>}
                            </Col>
                            <Col xs={6}>
                                <Button
                                    bsStyle="success"
                                    bsSize="small"
                                    onClick={() => {
                                        this.setState({
                                            showEmailsModal: false,
                                            confirmMessageEmails: [],
                                            emailRecipients: emailModalRecipients
                                        }, () => this.handleRecipients())
                                    }}
                                >
                                    OK
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>}
            </form> : null
    }
}

EmailEditor.defaultProps = {
    withFollowUpDate: true
};

EmailEditor.propTypes = {
    emailType: PropTypes.string,
    bulkEmailType: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    uploadAttachment: PropTypes.func,
    removeAttachment: PropTypes.func,
    defaultEmail: PropTypes.string,
    recipient: PropTypes.string,
    message: PropTypes.string,
    attachments: PropTypes.array,
    withFollowUpDate: PropTypes.bool,
    templateMode: PropTypes.bool,
    setCurrentEmail: PropTypes.func,
    templateId: PropTypes.number
};


const mapStateToProps = state => ({
    roles: state.auth.roles,
    clientId: state?.admin?.customerInfo?.id
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        ...api,
        renderEmail,
        getTemplate,
        getClient,
        getRecipients,
        saveEditorSettings
    }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailEditor)
